/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_POOL_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_POOL_WEB_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN,
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    redirectSignIn: process.env.REACT_APP_SOCIAL_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_SOCIAL_SIGN_OUT_URL,
    responseType: 'token',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 6,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
};

export default awsmobile;
