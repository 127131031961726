import { lazy } from 'react';

const PagesRoutes = [
  // PAGES
  // {
  //   path: '/',
  //   component: lazy(() => import('../../view/home')),
  //   exact: true,
  //   layout: 'VerticalLayout',
  // },
  {
    path: '/pages/blank-page',
    component: lazy(() => import('../../view/pages/blank')),
    exact: true,
    layout: 'VerticalLayout',
  },
  {
    path: '/pages/error-404',
    component: lazy(() => import('../../view/pages/errors/404')),
    exact: true,
    layout: 'FullLayout',
  },
  {
    path: '/properties',
    component: lazy(() => import('../../view/pages/properties')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Properties Review',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    path: '/properties/property-review/:propertyId',
    component: lazy(() =>
      import('../../view/pages/properties/property-review/index')
    ),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Property Review',
    role: 'cataloger',
  },
  {
    path: '/properties-reviewed',
    component: lazy(() =>
      import('../../view/pages/properties/properties-reviewed')
    ),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Reviewed Properties',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    path: '/properties-geotagging',
    component: lazy(() =>
      import('../../view/pages/properties/properties-geotagging')
    ),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Properties Geotagging',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    path: '/seo-description',
    component: lazy(() => import('../../view/pages/SeoDecription')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'SEO',
    role: 'seo',
  },
  {
    path: '/system-users',
    component: lazy(() => import('../../view/pages/system-users')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'System Users',
  },
  {
    path: '/roles',
    component: lazy(() => import('../../view/pages/system-users/roles')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Roles',
  },
  {
    path: '/system-users/system-user-form',
    component: lazy(() => import('../../view/pages/system-user-form')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'System User Form',
  },
  {
    path: '/system-users/system-user-form/:id',
    component: lazy(() => import('../../view/pages/system-user-form')),
    layout: 'VerticalLayout',
    exact: true,
    name: 'System User Form',
  },
  {
    path: '/properties-geotagging/:id',
    component: lazy(() =>
      import(
        '../../view/pages/properties/properties-geotagging/property-details'
      )
    ),
    layout: 'VerticalLayout',
    exact: true,
    name: 'Properties Geotagging',
    role: 'cataloger',
  },
  // {
  //   path: '/login',
  //   component: lazy(() => import('../../view/pages/login')),
  //   layout: 'FullLayout',
  //   exact: true,
  // },
];

export default PagesRoutes;
