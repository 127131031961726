import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Col, Breadcrumb } from 'antd';
// import _ from 'lodash';
// import PagesRoutes from '../../../../router/routes/Pages';

const BreadCrumbs = (props) => {
  // const {
  //   breadCrumbParent,
  //   breadCrumbParent2,
  //   breadCrumbParent3,
  //   breadCrumbActive,
  // } = props;

  const location = useLocation();
  const match = useRouteMatch();

  // const routeName = findRouteName(match.url);
  const pathname = location.pathname.split('/');

  const breadcrumbNameMap = {
    '/properties': 'Properties',
    '/properties/property-review': 'Property Review',
    '/properties-reviewed': 'Reviewed Properties',
    '/properties-geotagging': 'Propsure Geotagging',
    '/system-users': 'System Users',
    '/roles': 'Roles',
    '/seo-description': 'Seo Description',
  };

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <Col>
      <Breadcrumb className="hp-d-flex hp-flex-wrap">
        {breadcrumbItems}
        {/* <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>

        {breadCrumbParent === 'Components' ? (
          <Breadcrumb.Item>Components</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item>{breadCrumbParent}</Breadcrumb.Item>
        )}

        {breadCrumbParent2 && (
          <Breadcrumb.Item>{breadCrumbParent2}</Breadcrumb.Item>
        )}

        {breadCrumbParent3 && (
          <Breadcrumb.Item>{breadCrumbParent3}</Breadcrumb.Item>
        )}

        <Breadcrumb.Item>{breadCrumbActive}</Breadcrumb.Item> */}
      </Breadcrumb>
    </Col>
  );
};

export default BreadCrumbs;
