import React from 'react';

import { Col, Row } from 'antd';

import MenuLogo from '../../../layout/components/menu/logo';

export default function Header() {
  return (
    <Row
      align="middle"
      justify="space-between"
      className="hp-px-sm-16 hp-px-64 hp-py-16 hp-mb-48 hp-border-bottom-1 hp-border-color-dark-70"
    >
      <Col>
        <MenuLogo />
      </Col>
    </Row>
  );
}
