import Axios from 'axios';
import Cookie from 'js-cookie';

const token = Cookie.get('graana_admin_access_token');
const setHeader = (headers, contentType) => {
  return {
    'Accept-Encoding': 'gzip,deflate,compress',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
    ...headers,
  };
};

const handleResponse = (response) => {
  const { statusText, data: newData, status } = response;
  return {
    statusText,
    data: newData,
    statusCode: status,
  };
};

const handleError = (error) => {
  throw error;
};

export const doPropsureGet = async (
  path,
  headers = {},
  baseURL = process.env.REACT_APP_PROPSURE_BASE_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'GET',
      headers: setHeader(headers),
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
export const doUserGet = async (
  path,
  headers = {},
  baseURL = process.env.REACT_APP_BACKEND_USER_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'GET',
      headers: setHeader(headers),
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const doUserPost = async (
  path,
  data,
  headers = {},
  baseURL = process.env.REACT_APP_BACKEND_USER_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'POST',
      headers: setHeader(headers),
      data,
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const doUserPut = async (
  path,
  data,
  headers = {},
  baseURL = process.env.REACT_APP_BACKEND_USER_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'PUT',
      headers: setHeader(headers),
      data,
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const doGet = async (
  path,
  params,
  headers = {},
  baseURL = process.env.REACT_APP_BACKEND_BASE_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'GET',
      header: setHeader(headers),
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const doPut = async (
  path,
  data,
  headers = {},
  baseURL = process.env.REACT_APP_BACKEND_BASE_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'PUT',
      headers: setHeader(headers),
      data,
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const doGetGraana = async (
  path,
  params,
  baseURL = process.env.REACT_APP_BACKEND_USER_URL
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'GET',
    });

    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
export const doPost = async (
  path,
  data,
  params,
  baseURL = process.env.REACT_APP_BACKEND_BASE_URL
) => {
  try {
    const response = await Axios({
      url: `${path}`,
      baseURL,
      method: 'POST',
      data,
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
