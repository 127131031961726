import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Amplify } from 'aws-amplify';
import { ConfigProvider } from 'antd';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IntlProvider } from 'react-intl';
import awsExports from './aws-exports';
import AppLocale from './languages';
import Router from './router/Router';
import { setPropertyTypes } from './utils/API';
import {
  setAdminUserStore,
  setPropertyTypesStore,
} from './redux/customise/customiseActions';

global.ASYNC_VALIDATOR_NO_WARNING = 1;

Amplify.configure(awsExports);

export default function App() {
  // Redux
  const customise = useSelector((state) => state.customise);

  const history = useHistory();

  // Lang
  const currentAppLocale = AppLocale[customise.language];

  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookie.get('graana_admin_access_token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      const fetchPropertyType = async () => {
        const res = await setPropertyTypes();
        dispatch(setPropertyTypesStore(res.data.data));
      };
      fetchPropertyType();
      axios
        .get(`${process.env.REACT_APP_BACKEND_USER_URL}authentication/admin/me`)
        .then((response) => {
          dispatch(setAdminUserStore(response.data.data));
        })
        .catch((/* error */) => {
          Cookie.remove('graana_admin_access_token');
          history.push('/login');
        });
    } else {
      history.push('/login');
    }
  }, [dispatch, history]);

  useEffect(() => {
    document.querySelector('html').setAttribute('lang', customise.language);
  }, [customise]);

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={customise.direction}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}
