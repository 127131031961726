import { Suspense, useEffect } from 'react';

// Motion
import { motion } from 'framer-motion/dist/framer-motion';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Cookie from 'js-cookie';
import { theme } from '../redux/customise/customiseActions';

// Router

// Routes
import Routes from './routes';
// import PrivateRoute from './PrivateRoute';

// Layouts
import VerticalLayout from '../layout/VerticalLayout';
import HorizontalLayout from '../layout/HorizontalLayout';
import FullLayout from '../layout/FullLayout';

// Components
import Home from '../view/home';
import Login from '../view/pages/login';

function ProtectedRoute({ render: Component, ...props }) {
  const token = Cookie.get('graana_admin_access_token');
  return (
    <Route
      {...props}
      render={() =>
        // () => <Component {...props} />
        token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default function Router() {
  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();
  const { adminUser } = customise;
  // Dark Mode

  useEffect(() => {
    document.querySelector('body').classList.add(customise.theme);
    dispatch(theme(customise.theme));
  }, [customise.theme, dispatch]);

  // RTL
  useEffect(() => {
    if (customise.direction === 'ltr') {
      document.querySelector('html').setAttribute('dir', 'ltr');
    } else if (customise.direction === 'rtl') {
      document.querySelector('html').setAttribute('dir', 'rtl');
    }
  }, [customise.direction]);

  // Default Layout
  const DefaultLayout = customise.layout; // FullLayout or VerticalLayout

  // All of the available layouts
  const Layouts = { VerticalLayout, HorizontalLayout, FullLayout };

  // Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = (layout) => {
    const LayoutRoutes = [];
    const LayoutPaths = [];
    if (Routes) {
      // Checks if Route layout or Default layout matches current layout
      Routes.filter(
        (route) =>
          route.layout === layout &&
          (LayoutRoutes.push(route), LayoutPaths.push(route.path))
      );
    }

    return { LayoutRoutes, LayoutPaths };
  };

  const getPath = (route) => {
    if (
      (adminUser &&
        adminUser.roles &&
        adminUser.roles[0] &&
        (adminUser.roles[0].roleName === route.role ||
          adminUser.roles[0].roleName === route.subRole)) ||
      (adminUser &&
        adminUser.roles &&
        adminUser.roles[0] &&
        adminUser.roles[0].roleName === 'super-admin')
    ) {
      // console.log('admin and role');
      return route.path;
    }
    if (!adminUser) {
      // console.log('not admin');
      return route.path;
    }
    // console.log('last one');
    return '/';
  };

  // Return Route to Render
  const ResolveRoutes = () =>
    Object.keys(Layouts).map((layout, index) => {
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

      let LayoutTag;
      if (DefaultLayout === 'HorizontalLayout') {
        if (layout === 'VerticalLayout') {
          LayoutTag = Layouts.HorizontalLayout;
        } else {
          LayoutTag = Layouts[layout];
        }
      } else {
        LayoutTag = Layouts[layout];
      }
      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag>
            <Switch>
              {LayoutRoutes.map((route) => {
                return (
                  <ProtectedRoute
                    key={getPath(route)}
                    path={getPath(route)}
                    exact={route.exact === true}
                    render={(props) => {
                      return (
                        <Suspense fallback={null}>
                          {route.layout === 'FullLayout' ? (
                            <route.component {...props} />
                          ) : (
                            <motion.div
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{
                                type: 'spring',
                                duration: 0.5,
                                delay: 0.5,
                              }}
                            >
                              <route.component {...props} />
                            </motion.div>
                          )}
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  return (
    <BrowserRouter>
      <Switch>
        {ResolveRoutes()}
        <Route
          exact
          path="/login"
          render={() => {
            return (
              <Layouts.FullLayout>
                <Login />
              </Layouts.FullLayout>
            );
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Layouts.FullLayout>
                <Home />
              </Layouts.FullLayout>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}
