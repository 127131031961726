import { camelCase, lowerFirst, startCase } from 'lodash';

const ARAB = Number(1000000000);
const CRORE = Number(10000000);
const LAC = 100000;

export default function lowerString(value) {
  return lowerFirst(value.replace(/\s+/g, '-'));
}

export function camelCaseString(value) {
  return camelCase(value);
}

export function upperString(value) {
  return startCase(value);
}

export function convertPrice(price) {
  const newPrice = parseFloat(price);
  if (!newPrice) {
    return 0;
  }
  if (newPrice >= ARAB) {
    return `${Math.round((newPrice / ARAB) * 100) / 100} arab`;
  }
  if (newPrice >= CRORE) {
    return `${Math.round((newPrice / CRORE) * 100) / 100} crore`;
  }
  if (newPrice >= LAC) {
    return `${Math.round((newPrice / LAC) * 100) / 100} lac`;
  }
  return newPrice;
}
