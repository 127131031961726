import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Form, Input, Button, Spin, message } from 'antd';
import Cookie from 'js-cookie';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RiErrorWarningLine } from 'react-icons/ri';
import Header from './header';
import { setAdminUserStore } from '../../../redux/customise/customiseActions';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const adminUser = useSelector((state) => state.customise.adminUser);

  useEffect(() => {
    if (adminUser) {
      if (adminUser.roles && adminUser.roles[0]) {
        if (adminUser.roles[0].roleName === 'seo') {
          history.push('/seo-description');
        } else if (adminUser.roles[0].roleName === 'cataloger') {
          history.push('/properties');
        } else if (adminUser.roles[0].roleName === 'super-admin') {
          history.push('/properties');
        } else if (adminUser.roles[0].roleName === 'admin') {
          history.push('/properties');
        }
      }
    }
  }, [adminUser, history]);

  async function onFinish() {
    setLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      if (
        user?.attributes?.['custom:user_role'] === 'admin' ||
        user?.attributes?.['custom:user_role'] === 'dataEntry' ||
        user?.attributes?.['custom:user_role'] === 'manager' ||
        user?.attributes?.['custom:user_role'] === 'mediaExecutive'
      ) {
        const tokenString = user?.signInUserSession?.accessToken?.jwtToken;
        const idToken = user?.signInUserSession?.idToken?.jwtToken;
        axios.defaults.headers.common.Authorization = `Bearer ${tokenString}`;

        Cookie.set('graana_admin_access_token', `${idToken}`, {
          expires: 14,
        });
        axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_USER_URL}authentication/admin/me`
          )
          .then((response) => {
            dispatch(setAdminUserStore(response.data.data));
            if (response.data.data.roles && response.data.data.roles[0]) {
              if (response.data.data.roles[0].roleName === 'seo') {
                history.push('/seo-description');
              } else if (response.data.data.roles[0].roleName === 'cataloger') {
                history.push('/properties');
              } else if (
                response.data.data.roles[0].roleName === 'super-admin'
              ) {
                history.push('/properties');
              } else if (response.data.data.roles[0].roleName === 'admin') {
                history.push('/properties');
              }
            }
          })
          .catch(() => {
            Cookie.remove('graana_admin_access_token');
          });
      } else {
        errorMessage();
        setLoading(false);
      }
    } catch (error) {
      errorMessage();
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'email':
        setUsername(e.target.value);
        break;

      case 'password':
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const errorMessage = () => {
    message.error({
      content: 'Incorrect email/password. Please try again.',
      icon: <RiErrorWarningLine className="remix-icon" />,
    });
  };

  return (
    <Row
      className="hp-authentication-page hp-d-flex"
      style={{ flexDirection: 'column' }}
    >
      {/* <Background /> */}
      <Col span={24}>
        <Header />
      </Col>

      <Col flex="1 0 0" className="hp-px-32" style={{ marginTop: 100 }}>
        <Row
          className="hp-h-100 hp-m-auto"
          align="middle"
          style={{ maxWidth: 360 }}
        >
          <Col span={24}>
            <h1>Login</h1>
            <Form
              layout="vertical"
              name="basic"
              // initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                label="Email :"
                className="hp-mb-16"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email',
                    type: 'email',
                  },
                ]}
              >
                <Input value={username} onChange={handleChange} name="email" />
              </Form.Item>
              <>
                <Form.Item
                  label="Password :"
                  name="password"
                  className="hp-mb-8"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password',
                    },
                  ]}
                >
                  <Input.Password
                    id="warning2"
                    value={password}
                    onChange={handleChange}
                    name="password"
                  />
                </Form.Item>

                <Form.Item className="hp-mt-16 hp-mb-0">
                  <Button block type="primary" htmlType="submit">
                    Sign in
                  </Button>
                </Form.Item>
              </>
            </Form>
          </Col>
          <Col span={24} className="text-center hp-pt-10">
            {loading ? <Spin /> : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
