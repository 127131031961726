import {
  doGet,
  doPropsureGet,
  doUserGet,
  doUserPost,
  doUserPut,
  doGetGraana,
  doPut,
  doPost,
} from './Request';
import { camelCaseString } from '../common/action';

export const getPropsureId = async (propertyId) => {
  const res = await doGet(`properties/getPropsureId?propertyId=${propertyId}`);
  return res;
};

export const getPropertyLogs = async (propertyId) => {
  const res = await doGet(`properties/logs/${propertyId}`);
  return res;
};

export const exportProperties = async (propertyIds) => {
  const res = await doPost('properties/getProperties', {
    propertyIds,
  });
  const timestamp = Date.now();
  const fileName = `properties_${timestamp}.csv`;
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(res.data)}`
  );
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const savePropertyPropsure = async ({
  propertyId,
  propsureId,
  lat,
  lon,
}) => {
  doPost('properties/savePropertyPropsure', {
    propertyId,
    propsureId,
    lat,
    lon,
  });
};

export const getCitiesDropdown = async () => {
  const res = await doPropsureGet(`cities?secure=true`);
  return res;
};
export const getHousingSchemesDropdown = async (cityId) => {
  const res = await doPropsureGet(
    `housing-scheme?cityId=${cityId}&secure=true`
  );
  return res;
};
export const getPhaseSectorsDropdown = async (housingSchemeId) => {
  const res = await doPropsureGet(
    `phase-sector?housingSchemeId=${housingSchemeId}&secure=true`
  );
  return res;
};
export const getBlockSubsectorsDropdown = async (phaseSubsectorId) => {
  const res = await doPropsureGet(
    `block-subsector?phaseSectorId=${phaseSubsectorId}&secure=true`
  );
  return res;
};
export const getPlotsDropdown = async (phaseSubsectorId, blockSubsectorId) => {
  const res = await doPropsureGet(
    `plots?phaseSectorId=${phaseSubsectorId}${
      blockSubsectorId ? `&blockSubsectorId=${blockSubsectorId}` : ''
    }&secure=true`
  );
  return res;
};

export const getProperty = async (propertyId, systemUserId) => {
  const res = await doGet(
    `properties/reviewProperties?propertyId=${propertyId}&systemUserId=${systemUserId}`
  );
  return res;
};

export const getProperties = async (
  systemUserId,
  page,
  pageSize,
  searchType,
  inputSearch,
  typeFilter,
  statusFilter,
  agencyFilter,
  isSuperAdmin,
  startDate,
  endDate,
  reviewed,
  geoTaggedProperties
) => {
  const res = await doGet(
    `properties/reviewProperties?systemUserId=${systemUserId}&pageSize=${pageSize}&page=${page}${
      searchType ? `&searchBy=${camelCaseString(searchType)}` : ''
    }${inputSearch ? `&q=${encodeURIComponent(inputSearch)}` : ''}${
      typeFilter &&
      (typeFilter === 'Residential' ||
        typeFilter === 'Commercial' ||
        typeFilter === 'Plot')
        ? `&propertyType=${camelCaseString(typeFilter)}`
        : ''
    }${
      typeFilter &&
      typeFilter !== 'Residential' &&
      typeFilter !== 'Commercial' &&
      typeFilter !== 'Plot'
        ? `&propertySubtype=${camelCaseString(typeFilter)}`
        : ''
    }${statusFilter ? `&propertyStatus=${camelCaseString(statusFilter)}` : ''}${
      agencyFilter
        ? `&withOutAgencies=${agencyFilter === 'No Agency' ? 'true' : 'false'}`
        : ''
    }${reviewed ? `&reviewed=true` : ''}${
      geoTaggedProperties ? `&geoTaggedProperties=true` : ''
    }${isSuperAdmin ? `&isSuperAdmin=true` : ''}${
      startDate ? `&startDate=${startDate}` : ''
    }${endDate ? `&endDate=${endDate}` : ''}`
  );
  return res;
};

export const setPropertyTypes = async () => {
  const res = await doGet('properties/getPropertyTypes');
  return res;
};

export const getUserRoles = async () => {
  const res = await doUserGet('authentication/role');
  return res;
};

export const getAreaGroups = async () => {
  const res = await doUserGet('authentication/area-group');
  return res;
};

export const getInquiries = async (
  page,
  pageSize,
  searchType,
  inputSearch,
  startDate,
  endDate
) => {
  const res = await doGet(
    `/inquiries/?page=${page}&pageSize=${pageSize}
    ${searchType ? `&searchBy=${camelCaseString(searchType)}` : ''}${
      inputSearch ? `&q=${encodeURIComponent(inputSearch)}` : ''
    }${startDate ? `&startDate=${startDate}` : ''}${
      endDate ? `&endDate=${endDate}` : ''
    }`
  );
  return res;
};

export const exportInquiries = async (inquiryIds) => {
  const res = await doPost('inquiries/exportInquiries', {
    inquiryIds,
  });
  const timestamp = Date.now();
  const fileName = `inquiries_${timestamp}.csv`;
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(res.data)}`
  );
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getAdminUsers = async (page, paginationToken) => {
  const res = await doUserGet(
    `/authentication/admin?filter=&page=${page}${
      paginationToken ? `&paginationToken=${paginationToken}` : ''
    }`
  );
  return res;
};

export const getAdminUsersSearch = async (searchType, inputSearch) => {
  let filter = `${searchType}^="${inputSearch}"`;
  if (searchType === 'phone')
    filter = `phone_number="${inputSearch?.replace('+', '%2B')}"`;

  const res = await doUserGet(`/authentication/admin?filter=${filter}`);
  return res;
};

export const getSingleAdminUser = async (id) => {
  const res = await doUserGet(`/authentication/admin/attributes/${id}`);
  return res;
};

export const createAdminUser = async ({
  uuid,
  email,
  name,
  phone,
  roleId,
  areaIds,
}) => {
  const payload = {
    userName: uuid,
    attributes: [
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: 'phone_number',
        Value: phone,
      },
      {
        Name: 'email_verified',
        Value: 'true',
      },
      { Name: 'phone_number_verified', Value: 'true' },
      {
        Name: 'custom:user_type',
        Value: 'new',
      },
      {
        Name: 'custom:user_role',
        Value: 'admin',
      },
      {
        Name: 'name',
        Value: name,
      },
      // {
      //   Name: 'custom:is_super_admin',
      //   Value: 'true',
      // },
    ],
    areaGroupIds: areaIds,
    roleIds: roleId,
  };
  const res = await doUserPost('authentication/admin', payload);
  return res;
};

export const editAdminUser = async ({
  userId,
  email,
  name,
  phone,
  roleId,
  areaIds,
}) => {
  const payload = {
    attributes: [
      {
        Name: 'phone_number',
        Value: phone,
      },
      {
        Name: 'name',
        Value: name,
      },
      // {
      //   Name: 'email',
      //   Value: email,
      // },
    ],
    userName: userId,
    areaGroupIds: areaIds,
    roleIds: roleId,
  };
  const res = await doUserPut(`/authentication/admin/profile`, payload);
  return res;
};
export const getCitiesApi = () => doGetGraana('data/cities');

export const getAreas = (searchQuery, cityId) => {
  return doGetGraana(
    `search/cityArea?searchQuery='${searchQuery}'${
      cityId ? `&cityId=${cityId}` : ''
    }`
  );
};

export const getSEOData = ({
  cityId,
  purpose,
  type,
  areaId,
  subType,
  pageNum,
  pageSize,
}) => {
  return doGet(
    `seoDescription?page=${pageNum}${cityId ? `&cityId=${cityId}` : ''}${
      purpose ? `&purpose=${purpose}` : ''
    }${areaId ? `&areaId=${areaId}` : ''}${type ? `&type=${type}` : ''}${
      subType ? `&subType=${subType}` : ''
    }&pageSize=${pageSize}`
  );
};

export const PutSEOData = async (payload) => {
  const res = await doPut(`seoDescription`, payload);
  return res;
};

// http://localhost:3002/admin/seoDescription
