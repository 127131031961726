import { Home } from 'iconsax-react';

const company = [
  // {
  //   header: 'COMPANY',
  // },
  {
    id: 'reviewProperties',
    title: 'Properties for Review',
    icon: <Home set="curved" className="remix-icon" />,
    navLink: '/properties',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    id: 'reviewedProperties',
    title: 'Reviewed Properties',
    icon: <Home set="curved" className="remix-icon" />,
    navLink: '/properties-reviewed',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    id: 'propertiesGeotagging',
    title: 'Properties Geotagging',
    icon: <Home set="curved" className="remix-icon" />,
    navLink: '/properties-geotagging',
    role: 'cataloger',
    subRole: 'admin',
  },
  {
    id: 'systemUsers',
    title: 'System Users',
    icon: <i className="ri-user-line" />,
    navLink: '/system-users',
  },
  {
    id: 'roles',
    title: 'Roles',
    icon: <i className="ri-user-line" />,
    navLink: '/roles',
  },
  {
    id: 'seoDescription',
    title: 'Seo Description',
    icon: <i className="ri-search-line" />,
    navLink: '/seo-description',
    role: 'seo',
  },
];

export default company;
