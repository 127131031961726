import * as actionTypes from './customiseTypes';

export const theme = (value) => ({
  type: actionTypes.THEME,
  payload: value,
});

export const contentWidth = (value) => ({
  type: actionTypes.CONTENT_WIDTH,
  payload: value,
});

export const sidebarCollapsed = (value) => ({
  type: actionTypes.SIDEBAR_COLLAPSED,
  payload: value,
});

export const sidebarCollapseButton = (value) => ({
  type: actionTypes.SIDEBAR_COLLAPSE_BUTTON,
  payload: value,
});

export const layoutChange = (value) => ({
  type: actionTypes.LAYOUT_CHANGE,
  payload: value,
});

export const navigationFull = (value) => ({
  type: actionTypes.NAVIGATION_FULL,
  payload: value,
});

export const navigationBg = (value) => ({
  type: actionTypes.NAVIGATION_BG,
  payload: value,
});

export const direction = (value) => ({
  type: actionTypes.DIRECTION,
  payload: value,
});

export const switchLanguage = (value) => ({
  type: actionTypes.LANGUAGE,
  payload: value,
});

export const setPropertyTypesStore = (value) => ({
  type: actionTypes.SET_PROPERTY_TYPES,
  payload: value,
});

export const setAdminUserStore = (value) => ({
  type: actionTypes.SET_ADMIN_USER,
  payload: value,
});

export const setAdminUsersStore = (value) => ({
  type: actionTypes.SET_ADMIN_USERS,
  payload: value,
});
